<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">ADI and Spec Grading Quiz</h2>

      <p class="mb-2">
        Match the example parts from the "Example of Argumentation" in the video to the 4 parts of
        the argumentation process.
      </p>

      <p class="mb-2 pl-4">
        <v-row v-for="i in 4" :key="i.value" no-gutters class="mb-n3">
          <v-col class="pt-5" cols="3"> {{ argumentPart[i - 1] }} </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="9">
            <v-select
              v-model="inputs.argument[i - 1]"
              :items="argumentOptions"
              item-text="text"
              item-value="value"
              label="Select an option:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        argument: [],
      },
      argumentPart: ['a) Question', 'b) Evidence', 'c) Justification', 'd) Claim'],
      argumentOptions: [
        {text: 'The densities in method 1 and 2 are different for the same object.', value: 'a'},
        {text: 'The blue cylinder and clear cubes are the same substance.', value: 'b'},
        {text: 'Mass is an extensive property not related to substance identity.', value: 'c'},
        {text: 'Density data from methods 1 & 2.', value: 'd'},
        {
          text: 'Density is an intensive property and it will be the same if the substance is the same.',
          value: 'e',
        },
        {text: 'Are these objects made of the same material?', value: 'f'},
      ],
    };
  },
};
</script>
<style scoped></style>
